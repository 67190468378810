@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600&display=swap');

body *:not(i) {
  font-family: 'Source Sans Pro', sans-serif;
}
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.05) !important;
  border-radius: 20px !important;
}
::-webkit-scrollbar-thumb {
  border-radius: 20px !important;
  background: #2196f3 !important;
}
::-webkit-scrollbar {
  width: 5px !important;
}
header.ant-layout-header {
  position: sticky;
  z-index: 111;
  width: 100%;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header.ant-layout-header .logo {
  color: white;
  font-weight: bold;
  font-size: 22px;
  display: flex;
  align-items: center;
}
span.duration,
span.rel_duration {
  right: 0;
  color: white;
  bottom: 0.35rem;
  padding: 0px 6px;
  border-radius: 3px;
  background: black;
  position: absolute;
}
span.duration {
  right: 0.5rem;
  bottom: 1rem;
  border: 1px solid;
}

@media screen and (max-width: 720px) {
  header.ant-layout-header {
    padding: 0 15px;
  }
  header.ant-layout-header .logo {
    font-size: 18px;
    flex: 100%;
  }
  header.ant-layout-header .logo img.ant-image-img {
    height: 30px;
    width: 30px;
  }
  .site-layout-content .ant-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .stats {
    flex-direction: column;
  }
}

/* Loader CSS */
.tcm_loader {
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: black;
  font-size: 22px;
  background: rgba(0, 0, 0, 0.25);
  z-index: 999999;
}
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: ' ';
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: black;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
